import {
  USERS_CONNECTING,
  USERS_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  users: [],
  users_with_extensions: [],
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case USERS_CONNECTING:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: true,
        })
      };
    case USERS_DATA:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: false,
          ...action.payload
        })
      };
    default:
      return state;
  }
};
