import {
  ADDITIONALS_CONNECTING,
  ADDITIONALS_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  phone_protocol: 'tel:',
  search_options: [],
  available_datasets: [],
  freeswitch_destinations_loading: true,
  freeswitch_destinations: [],
  freeswitch_destinations_object_loading: true,
  freeswitch_destinations_object: []
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case ADDITIONALS_CONNECTING:
      return {
        ...state,
        timestamp: new Date(),
        loading: true
      };
    case ADDITIONALS_DATA:
      return {
        ...state,
        timestamp: new Date(),
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};
