import {
  VOICEMAILS_CONNECTING,
  VOICEMAILS_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  voicemails: [],
  voicemails_with_greetings: [],
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case VOICEMAILS_CONNECTING:
      return {
        ...state,
        timestamp: new Date(),
        loading: true
      };
    case VOICEMAILS_DATA:
      return {
        ...state,
        timestamp: new Date(),
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};
