import {
  VOICEMAILS_PENDING,
  VOICEMAILS_SUCCESS,
  VOICEMAIL_GREETINGS_PENDING,
  VOICEMAIL_GREETINGS_SUCCESS,
} from '../../types';

const VOICEMAILS_INITIALSTATE = {
  loading: null,
  voicemails: [],
};

export function voicemails_Reducer(state = VOICEMAILS_INITIALSTATE, action) {
  switch (action.type) {
    case VOICEMAILS_PENDING:
      return {
        ...state,
        loading: true
      };
    case VOICEMAILS_SUCCESS:
      return {
        loading: false,
        voicemails: action.payload,
      };
    default:
      return state;
  }
}
