import {
  RINGGROUPS_CONNECTING,
  RINGGROUPS_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  currentRinggroupLoading: true,
  combinedDataLoading: true,
  ringgroups: [],
  userRinggroups: [],
  ringgroupExtensions: [],
  combinedDataRinggroups: [],
  currentRinggroup: {},
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case RINGGROUPS_CONNECTING:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: true,
        })
      };
    case RINGGROUPS_DATA:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: false,
          ...action.payload
        })
      };
    default:
      return state;
  }
};
