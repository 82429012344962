export const FILTERS_DATA = 'FILTERS_DATA';

export const FIREBASE_CONNECTED = 'FIREBASE_CONNECTED';

export const CALLS_CONNECTING = 'CALLS_CONNECTING';
export const CALLS_DATA = 'CALLS_DATA';

export const UNHANDLED_MISSEDCALLS_PENDING = 'UNHANDLED_MISSEDCALLS_PENDING';
export const UNHANDLED_MISSEDCALLS_SUCCESS = 'UNHANDLED_MISSEDCALLS_SUCCESS';
export const UNHANDLED_MISSEDCALLS_FAILURE = 'UNHANDLED_MISSEDCALLS_FAILURE';

export const HANDLED_MISSEDCALLS_PENDING = 'HANDLED_MISSEDCALLS_PENDING';
export const HANDLED_MISSEDCALLS_SUCCESS = 'HANDLED_MISSEDCALLS_SUCCESS';
export const HANDLED_MISSEDCALLS_FAILURE = 'HANDLED_MISSEDCALLS_FAILURE';

export const MISSEDCALLS_ACTIONS_PENDING = 'MISSEDCALLS_ACTIONS_PENDING';
export const MISSEDCALLS_ACTIONS_SUCCESS = 'MISSEDCALLS_ACTIONS_SUCCESS';
export const MISSEDCALLS_ACTIONS_FAILURE = 'MISSEDCALLS_ACTIONS_FAILURE';

export const PROFILE_ACTIONS_PENDING = 'PROFILE_ACTIONS_PENDING';
export const PROFILE_ACTIONS_SUCCESS = 'PROFILE_ACTIONS_SUCCESS';
export const PROFILE_ACTIONS_FAILURE = 'PROFILE_ACTIONS_FAILURE';

export const RINGGROUPS_PENDING = 'RINGGROUPS_PENDING';
export const RINGGROUPS_SUCCESS = 'RINGGROUPS_SUCCESS';
export const RINGGROUPS_FAILURE = 'RINGGROUPS_FAILURE';

export const FIRESTORE_RINGGROUP_PENDING = 'FIRESTORE_RINGGROUP_PENDING';
export const FIRESTORE_RINGGROUP_SUCCESS = 'FIRESTORE_RINGGROUP_SUCCESS';

export const EXTENSIONS_PENDING = 'EXTENSIONS_PENDING';
export const EXTENSIONS_SUCCESS = 'EXTENSIONS_SUCCESS';
export const EXTENSIONS_FAILURE = 'EXTENSIONS_FAILURE';

export const DIDS_PENDING = 'DIDS_PENDING';
export const DIDS_SUCCESS = 'DIDS_SUCCESS';
export const DIDS_FAILURE = 'DIDS_FAILURE';

export const ANNOUNCEMENTS_PENDING = "ANNOUNCEMENTS_PENDING";
export const ANNOUNCEMENTS_SUCCESS = "ANNOUNCEMENTS_SUCCESS";
export const ANNOUNCEMENTS_FAILURE = "ANNOUNCEMENTS_FAILURE";

export const UPLOAD_ANNOUNCEMENT_PENDING = "UPLOAD_ANNOUNCEMENT_PENDING";
export const UPLOAD_ANNOUNCEMENT_SUCCESS = "UPLOAD_ANNOUNCEMENT_SUCCESS";
export const UPLOAD_ANNOUNCEMENT_FAILURE = "UPLOAD_ANNOUNCEMENT_FAILURE";

export const RINGGROUP_USERSTATE_PENDING = 'RINGGROUP_USERSTATE_PENDING';
export const RINGGROUP_USERSTATE_SUCCESS = 'RINGGROUP_USERSTATE_SUCCESS';
export const RINGGROUP_USERSTATE_FAILURE = 'RINGGROUP_USERSTATE_FAILURE';

export const PROFILE_UPDATE_WIZARD_PENDING = 'PROFILE_UPDATE_WIZARD_PENDING';
export const PROFILE_UPDATE_WIZARD_SUCCESS = 'PROFILE_UPDATE_WIZARD_SUCCESS';
export const PROFILE_UPDATE_WIZARD_FAILURE = 'PROFILE_UPDATE_WIZARD_FAILURE';

export const WIZARD_PASSSWORD_CHANGE_PENDING = 'WIZARD_PASSSWORD_CHANGE_PENDING';
export const WIZARD_PASSSWORD_CHANGE_SUCCESS = 'WIZARD_PASSSWORD_CHANGE_SUCCESS';
export const WIZARD_PASSSWORD_CHANGE_FAILURE = 'WIZARD_PASSSWORD_CHANGE_FAILURE';

export const PASSWORD_RESET_ACTIONS_IDLE = 'PASSWORD_RESET_ACTIONS_IDLE';
export const PASSWORD_RESET_ACTIONS_PENDING = 'PASSWORD_RESET_ACTIONS_PENDING';
export const PASSWORD_RESET_ACTIONS_SUCCESS = 'PASSWORD_RESET_ACTIONS_SUCCESS';
export const PASSWORD_RESET_ACTIONS_FAILURE = 'PASSWORD_RESET_ACTIONS_FAILURE';

export const PROFILE_CREATE_WIZARD_PENDING = 'PROFILE_CREATE_WIZARD_PENDING';
export const PROFILE_CREATE_WIZARD_SUCCESS = 'PROFILE_CREATE_WIZARD_SUCCESS';
export const PROFILE_CREATE_WIZARD_FAILURE = 'PROFILE_CREATE_WIZARD_FAILURE';

export const ACTIONS_UPDATE_IDLE = 'ACTIONS_UPDATE_IDLE';
export const ACTIONS_UPDATE_PENDING = 'ACTIONS_UPDATE_PENDING';
export const ACTIONS_UPDATE_SUCCESS = 'ACTIONS_UPDATE_SUCCESS';
export const ACTIONS_UPDATE_FAILURE = 'ACTIONS_UPDATE_FAILURE';

export const TIMECONDITION_PENDING = 'TIMECONDITION_PENDING';
export const TIMECONDITION_SUCCESS = 'TIMECONDITION_SUCCESS';
export const TIMECONDITION_UPDATE_SUCCESS = 'TIMECONDITION_UPDATE_SUCCESS';
export const TIMECONDITION_FAILURE = 'TIMECONDITION_FAILURE';

export const TIMECONDITIONS_PENDING = 'TIMECONDITIONS_PENDING';
export const TIMECONDITIONS_SUCCESS = 'TIMECONDITIONS_SUCCESS';
export const TIMECONDITIONS_FAILURE = 'TIMECONDITIONS_FAILURE';

export const USERS_PENDING = 'USERS_PENDING';
export const USERS_SUCCESS = 'USERS_SUCCESS';

export const ACTION_PENDING = 'ACTION_PENDING';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FAILURE = 'ACTION_FAILURE';

export const UPDATE_ACTION_PENDING = 'UPDATE_ACTION_PENDING';
export const UPDATE_ACTION_SUCCESS = 'UPDATE_ACTION_SUCCESS';
export const UPDATE_ACTION_FAILURE = 'UPDATE_ACTION_FAILURE';

export const ACTION_WIZARD_IDLE = 'ACTION_WIZARD_IDLE';
export const ACTION_WIZARD_PENDING = 'ACTION_WIZARD_PENDING';
export const ACTION_WIZARD_SUCCESS = 'ACTION_WIZARD_SUCCESS';
export const ACTION_WIZARD_FAILURE = 'ACTION_WIZARD_FAILURE';

export const RINGGROUP_PENDING = 'RINGGROUP_PENDING';
export const RINGGROUP_SUCCESS = 'RINGGROUP_SUCCESS';

export const FIRESTORE_RINGGROUPS_PENDING = 'FIRESTORE_RINGGROUPS_PENDING';
export const FIRESTORE_RINGGROUPS_SUCCESS = 'FIRESTORE_RINGGROUPS_SUCCESS';

export const ORGANIZATION_PENDING = 'ORGANIZATION_PENDING';
export const ORGANIZATION_SUCCESS = 'ORGANIZATION_SUCCESS';

export const CALLLOG_PENDING = 'CALLLOG_PENDING';
export const CALLLOG_SUCCESS = 'CALLLOG_SUCCESS';

export const ERROR_FAILURE = 'ERROR_FAILURE';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const ANNOUNCEMENT_SAMPLES_PUSH = 'ANNOUNCEMENT_SAMPLES_PUSH';
export const ANNOUNCEMENT_SAMPLES_CHUNK = 'ANNOUNCEMENT_SAMPLES_CHUNK';

export const FIRESTORE_RINGGROUP_EXTENSIONS_PENDING = 'FIRESTORE_RINGGROUP_EXTENSIONS_PENDING';
export const FIRESTORE_RINGGROUP_EXTENSIONS_SUCCESS = 'FIRESTORE_RINGGROUP_EXTENSIONS_SUCCESS';

export const RINGGROUP_EXTENSIONS_PENDING = 'RINGGROUP_EXTENSIONS_PENDING';
export const RINGGROUP_EXTENSIONS_SUCCESS = 'RINGGROUP_EXTENSIONS_SUCCESS';

export const FIRESTORE_EXTENSIONS_PENDING = 'FIRESTORE_EXTENSIONS_PENDING';
export const FIRESTORE_EXTENSIONS_SUCCESS = 'FIRESTORE_EXTENSIONS_SUCCESS';

export const FIRESTORE_USERS_PENDING = 'FIRESTORE_USERS_PENDING';
export const FIRESTORE_USERS_SUCCESS = 'FIRESTORE_USERS_SUCCESS';

export const VOICEMAILS_PENDING = 'VOICEMAILS_PENDING';
export const VOICEMAILS_SUCCESS = 'VOICEMAILS_SUCCESS';

export const VOICEMAIL_GREETINGS_PENDING = 'VOICEMAIL_GREETINGS_PENDING';
export const VOICEMAIL_GREETINGS_SUCCESS = 'VOICEMAIL_GREETINGS_SUCCESS';

export const ERRORS_CHUNK = 'ERRORS_CHUNK';
export const ERRORS_CLEAR = 'ERRORS_CLEAR';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const FORMLOAD = 'FORMLOAD';

// new
export const CURRENT_USER_CONNECTING = 'CURRENT_USER_CONNECTING';
export const CURRENT_USER_DATA = 'CURRENT_USER_DATA';
export const NUMBERS_CONNECTING = 'NUMBERS_CONNECTING';
export const NUMBERS_DATA = 'NUMBERS_DATA';
export const EXTENSIONS_CONNECTING = 'EXTENSIONS_CONNECTING';
export const EXTENSIONS_DATA = 'EXTENSIONS_DATA';
export const USERS_CONNECTING = 'USERS_CONNECTING';
export const USERS_DATA = 'USERS_DATA';
export const RINGGROUPS_CONNECTING = 'RINGGROUPS_CONNECTING';
export const RINGGROUPS_DATA = 'RINGGROUPS_DATA';
export const MISSEDCALLS_CONNECTING = 'MISSEDCALLS_CONNECTING';
export const MISSEDCALLS_DATA = 'MISSEDCALLS_DATA';
export const VOICEMAILS_CONNECTING = 'VOICEMAILS_CONNECTING';
export const VOICEMAILS_DATA = 'VOICEMAILS_DATA';
export const ANNOUNCEMENTS_CONNECTING = 'ANNOUNCEMENTS_CONNECTING';
export const ANNOUNCEMENTS_DATA = 'ANNOUNCEMENTS_DATA';
export const ORGANIZATION_CONNECTING = 'ORGANIZATION_CONNECTING';
export const ORGANIZATION_DATA = 'ORGANIZATION_DATA';
export const TIMECONDITIONS_CONNECTING = 'TIMECONDITIONS_CONNECTING';
export const TIMECONDITIONS_DATA = 'TIMECONDITIONS_DATA';
export const CIDS_CONNECTING = 'CIDS_CONNECTING';
export const CIDS_DATA = 'CIDS_DATA';
export const PROFILE_CONNECTING = 'PROFILE_CONNECTING';
export const PROFILE_DATA = 'PROFILE_DATA';
export const VIEWS_CONNECTING = 'VIEWS_CONNECTING';
export const VIEWS_DATA = 'VIEWS_DATA';
export const TEST_CONNECTING = 'TEST_CONNECTING';
export const TEST_DATA = 'TEST_DATA';
export const ADDITIONALS_CONNECTING = 'ADDITIONALS_CONNECTING';
export const ADDITIONALS_DATA = 'ADDITIONALS_DATA';
