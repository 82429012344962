import {
  EXTENSIONS_PENDING,
  EXTENSIONS_SUCCESS,
  RINGGROUP_EXTENSIONS_PENDING,
  RINGGROUP_EXTENSIONS_SUCCESS,
  FIRESTORE_RINGGROUP_EXTENSIONS_PENDING,
  FIRESTORE_RINGGROUP_EXTENSIONS_SUCCESS,
  FIRESTORE_EXTENSIONS_PENDING,
  FIRESTORE_EXTENSIONS_SUCCESS
} from 'store/types';

const EXTENSIONS_INITIALSTATE = {
  loading: null,
  extensions: []
};

export function extensions_Reducer(state = EXTENSIONS_INITIALSTATE, action) {
  switch (action.type) {
    case EXTENSIONS_PENDING:
      return {
        ...state,
        loading: true
      };
    case EXTENSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        extensions: action.payload
      };
    default:
      return state;
  }
}

// firestore ringggroup extensions

const RINGGROUP_EXTENSIONS_INITIALSTATE = {
  loading: null,
  extensions: []
};

export function ringgroup_extensions_Reducer(state = RINGGROUP_EXTENSIONS_INITIALSTATE, action) {
  switch (action.type) {
    case RINGGROUP_EXTENSIONS_PENDING:
      return {
        ...state,
        loading: true
      };
    case RINGGROUP_EXTENSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        extensions: action.payload
      };
    default:
      return state;
  }
}

// firestore extensions

const FIRESTORE_EXTENSIONS_INITIALSTATE = {
  loading: null,
  extensions: []
};

export function firestore_extensions_Reducer(state = FIRESTORE_EXTENSIONS_INITIALSTATE, action) {
  switch (action.type) {
    case FIRESTORE_EXTENSIONS_PENDING:
      return {
        ...state,
        loading: true
      };
    case FIRESTORE_EXTENSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        extensions: action.payload
      };
    default:
      return state;
  }
}

// firestore ringggroup extensions

const FIRESTORE_RINGGROUP_EXTENSIONS_INITIALSTATE = {
  loading: null,
  extensions: []
};

export function firestore_ringgroup_extensions_Reducer(state = FIRESTORE_RINGGROUP_EXTENSIONS_INITIALSTATE, action) {
  switch (action.type) {
    case FIRESTORE_RINGGROUP_EXTENSIONS_PENDING:
      return {
        ...state,
        loading: true
      };
    case FIRESTORE_RINGGROUP_EXTENSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        extensions: action.payload
      };
    default:
      return state;
  }
}
