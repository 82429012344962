import {
  VIEWS_CONNECTING,
  VIEWS_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  views: {}
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case VIEWS_CONNECTING:
      return {
        ...state,
        timestamp: new Date(),
        loading: true
      };
    case VIEWS_DATA:
      return {
        ...state,
        timestamp: new Date(),
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};
