import {
  HANDLED_MISSEDCALLS_PENDING,
  HANDLED_MISSEDCALLS_SUCCESS,
  UNHANDLED_MISSEDCALLS_PENDING,
  UNHANDLED_MISSEDCALLS_SUCCESS,
} from '../../types';

const HANDLED_MISSEDCALLS_INITIALSTATE = {
  loading: null,
  calls: []
};

export function handled_missedcalls_Reducer(state = HANDLED_MISSEDCALLS_INITIALSTATE, action) {
  switch (action.type) {
    case HANDLED_MISSEDCALLS_PENDING:
      return {
        ...state,
        loading: true
      };
    case HANDLED_MISSEDCALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        calls: action.payload
      };
    default:
      return state;
  }
}

// Unhandled!

const UNHANDLED_MISSEDCALLS_INITIALSTATE = {
  loading: null,
  calls: []
};

export function unhandled_missedcalls_Reducer(state = UNHANDLED_MISSEDCALLS_INITIALSTATE, action) {
  switch (action.type) {
    case UNHANDLED_MISSEDCALLS_PENDING:
      return {
        ...state,
        loading: true
      };
    case UNHANDLED_MISSEDCALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        calls: action.payload
      };
    default:
      return state;
  }
}
