import {
  PROFILE_ACTIONS_PENDING,
  PROFILE_ACTIONS_SUCCESS,
  PROFILE_ACTIONS_FAILURE,
  ACTIONS_UPDATE_IDLE,
  ACTIONS_UPDATE_PENDING,
  ACTIONS_UPDATE_SUCCESS,
  ACTIONS_UPDATE_FAILURE,
  FIRESTORE_USERS_PENDING,
  FIRESTORE_USERS_SUCCESS,
  USERS_PENDING,
  USERS_SUCCESS
} from '../../types';

// profile_Reducer

const PROFILESTATE_INITIALSTATE = {
  profile: null,
  loading: false,
  error: null
};

export function profile_Reducer(state = PROFILESTATE_INITIALSTATE, action) {
  switch (action.type) {
    case PROFILE_ACTIONS_PENDING:
      return {
        ...state,
        loading: true
      };
    case PROFILE_ACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload
      };
    case PROFILE_ACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

// updaterstateReducer

const ACTIONS_UPDATE_INITIALSTATE = {
  response: null,
  loading: false,
  errors: null
};

export function updaterstateReducer(state = ACTIONS_UPDATE_INITIALSTATE, action) {
  switch (action.type) {
    case ACTIONS_UPDATE_IDLE:
      return {
        ...ACTIONS_UPDATE_INITIALSTATE,
      };
    case ACTIONS_UPDATE_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTIONS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload
      };
    case ACTIONS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    default:
      return state;
  }
}

const USERS_INITIALSTATE = {
  loading: null,
  users: []
};

export function users_Reducer(state = USERS_INITIALSTATE, action) {
  switch (action.type) {
    case USERS_PENDING:
      return {
        ...state,
        loading: true
      };
    case USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    default:
      return state;
  }
}
// firestore users!

const FIRESTORE_USERS_INITIALSTATE = {
  loading: null,
  users: []
};

export function firestore_users_Reducer(state = FIRESTORE_USERS_INITIALSTATE, action) {
  switch (action.type) {
    case FIRESTORE_USERS_PENDING:
      return {
        ...state,
        loading: true
      };
    case FIRESTORE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    default:
      return state;
  }
}
