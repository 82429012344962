import {
  ORGANIZATION_CONNECTING,
  ORGANIZATION_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  organization: {}
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case ORGANIZATION_CONNECTING:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: true,
        })
      };
    case ORGANIZATION_DATA:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: false,
          ...action.payload
        })
      };
    default:
      return state;
  }
};
