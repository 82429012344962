import {
  ACTION_WIZARD_IDLE,
  ACTION_WIZARD_PENDING,
  ACTION_WIZARD_SUCCESS,
  ACTION_WIZARD_FAILURE
} from 'store/types';

const ACTION_WIZARD_INITIALSTATE = {
  type: 'idle',
  response: null,
  loading: false,
  redirect: true,
  error: null
};

export default function wizardReducer(state = ACTION_WIZARD_INITIALSTATE, action) {
  switch (action.type) {
    case ACTION_WIZARD_IDLE:
      return ACTION_WIZARD_INITIALSTATE;
    case ACTION_WIZARD_PENDING:
      return {
        ...state,
        type: 'pending',
        loading: true
      };
    case ACTION_WIZARD_SUCCESS:
      return {
        ...state,
        type: 'success',
        loading: false,
        ...action.payload
      };
    case ACTION_WIZARD_FAILURE:
      return {
        ...state,
        type: 'failure',
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
}
