export default function (state = 'WIZARD_LOADING', action) {
  switch (action.type) {
    case 'WIZARD_LOADING':
      return {
        state: 'LOADING',
        ...action.payload
      }
    case 'WIZARD_READY':
      return {
        ...state,
        state: 'READY',
        ...action.payload
      };

    case 'WIZARD_SUBMITTING':
      return {
        ...state,
        state: 'SUBMITTING',
        ...action.payload
      };
    case 'WIZARD_COMPLETE':
      return {
        ...state,
        state: 'COMPLETE',
        ...action.payload
      };
    case 'WIZARD_ERROR':
      return {
        ...state,
        state: 'ERROR',
        ...action.payload
      };
    default:
      return state;
  }
}
