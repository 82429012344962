import {
  ORGANIZATION_PENDING,
  ORGANIZATION_SUCCESS,
} from '../../types';

const ORGANIZATION_INITIALSTATE = {
  loading: null,
  organization: {}
};

export function organization_Reducer(state = ORGANIZATION_INITIALSTATE, action) {
  switch (action.type) {
    case ORGANIZATION_PENDING:
      return {
        ...state,
        loading: true
      };
    case ORGANIZATION_SUCCESS:
      return {
        loading: false,
        organization: action.payload
      };
    default:
      return state;
  }
}
