import {
  ERRORS_CHUNK,
  ERRORS_CLEAR
} from 'store/types';


const INITIALSTATE = {
  errors: []
};

export function errors_Reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case ERRORS_CHUNK:
      return {
        errors: [...state.errors, action.payload]
      };
    case ERRORS_CLEAR:
      return {
        ...INITIALSTATE
      };
    default:
      return state;
  }
}
