/*!
=========================================================
* Portal.vaihde.io
=========================================================

* App Auther Janne Hailikari

=========================================================
*/
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Store from './store';
import "assets/scss/portal.vaihde.io.scss?v=1.8.0";
import Firebase, { FirebaseContext } from './Firebase';

import './i18n';

import './index.css'; // imports fonts

import { theme } from "assets/jss/customTheme";
import { ThemeProvider } from '@material-ui/core/styles';

// import { firebase, store } from './Instances';

// import { createBrowserHistory } from "history"; //tämä

import { Grid, Fade, CircularProgress } from "@material-ui/core";

const MainLayout = lazy(() => import('./Layouts/Main.js'));
const AuthLayout = lazy(() => import('./Layouts/Auth.js'));
const WizardLayout = lazy(() => import('./Layouts/Wizards.js'));
const Notifier = lazy(() => import('./components/Notifier'));

// const hist = createBrowserHistory(); //tämä
const firebase = new Firebase();
let store = new Store(firebase);

// function onAppInit(dispatch) {
//   //dispatch(organizationActions());
//   //dispatch(firestore_ringgroups_Actions());
//   return;
// }
//
// onAppInit(store.dispatch);


// You can spread routeProps to make them available to your rendered Component
function FadingRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => (
        <Fade in>
          <div>
            <Component {...routeProps} />
          </div>
        </Fade>
      )}
    />
  );
}

const Loader = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <Grid item xs={3}>
      <Fade in>
        <CircularProgress size={70} thickness={2} color="secondary" />
      </Fade>
    </Grid>
  </Grid>
)

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <FirebaseContext.Provider value={firebase}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <Notifier />
          <Router>
            <Switch>
              <FadingRoute path="/auth" component={AuthLayout} />
              <FadingRoute path="/wizard" component={WizardLayout} />
              <FadingRoute path="/main" component={MainLayout} />
              <Redirect from="/" to="/main/dashboard" />
            </Switch>
          </Router>
          <div id='modal'></div>
          <div id='snackbar' />
        </SnackbarProvider>
      </Provider>
    </FirebaseContext.Provider>
  </Suspense>,
  document.getElementById('root'),
);

export { store, firebase };
