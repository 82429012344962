import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  'en-US': {
    translation: {
      "Missed Calls": "Missed Calls",
      "Handled Calls": "Handled Calls",
      "Stats": "Stats",
      "User": "User",
      "Users": "Users",
      "User Account": "User Account",
      "User Accounts": "User Accounts",
      "Voicemails": "Voicemails",
      "voicemail": "voicemail",
      "Ring Group": "Ring Group",
      "Ring Groups": "Ring Groups",
      "Ringgroup": "Ringgroup",
      "Extension": "Extension",
      "Extensions": "Extensions",
      "Call Forwarding": "Call Forwarding",
      "Members": "Members",
      "Open Page": "Open Page",
      "More": "More",
      "Click for more details": "Click for more details",
      "Back": "Back",
      "Delay": "Delay",
      "Duration of ringing the extension": "Duration of ringing the extension",
      "Ring Duration": "Ring Duration",
      "Return": "Return",

      // extensions
      "Table shows list of Extensions that exist": "Table shows list of Extensions that exist",
      "Table shows list of User Accounts that exist and have login priveleges": "Table shows list of User Accounts that exist and have login priveleges",
      "Add Extensions": "Add Extensions",
      "Add User Account": "Add User Account",
      "No User Accounts Found": "No User Accounts Found",
      "Outbound CallerID": "Outbound CallerID",
      "Delete Selected Extensions": "Delete Selected Extensions",
      "Description": "Description",
      "Update Extensions": "Update Extensions",
      "Open Profile": "Open Profile",
      "Admin Priveleges": "Admin Priveleges",
      "Delete Selected Accounts": "Delete Selected Accounts",
      "Assign Priveleges": "Assign Priveleges",
      //RinggroupLinkTable
      "No Ringgroups Found": "No Ringgroups Found",
      "Select CallForward Destination": "Select CallForward Destination",
      "Enable Call Forwarding": "Enable Call Forwarding",
      "Call Forward": "Call Forward",
      "Activation": "Activation",
      "Most Recent Calls Below": "Most Recent Calls Below",
      "Time when extension ends ringing": "Time when extension ends ringing",
      "Ringing Ends": "Ringing Ends",
      "Manage Ringgroup Extensions": "Manage Ringgroup Extensions",
      "Confirm Calls": "Confirm Calls",
      "Time in seconds when ringing is started": "Time in seconds when ringing is started",
      "available values": "available values",
      "Delay and Duration make up": "Delay and Duration make up",
      "seconds as ending time": "seconds as ending time",
      "Ringgroup Timeout Option cuts off before extension has completed delay with duration ringing": "Ringgroup Timeout Option cuts off before extension has completed delay with duration ringing",
      "APPLY CHANGES": "APPLY CHANGES",
      "UNDO CHANGES": "UNDO CHANGES",
      "Reminder": "Reminder",
      "Remember to apply changes to take": "Remember to apply changes to take",
      "affect": "affect",
      "Enable Confirm Calls": "Enable Confirm Calls",
      "User Not Defined": "User Not Defined",
      "Simultaneous": "Simultaneous",
      "Sequence": "Sequence",
      "Random": "Random",
      "Appending extensions to ring all by delay and Duration": "Appending extensions to ring all by delay and Duration",
      "From 0 to 300 tier. Ringing each at a time with Ring Time": "From 0 to 300 tier. Ringing each at a time with Ring Time",
      "Randomly rings active extension one at a time, Ringtime determated by Duration": "Randomly rings active extension one at a time, Ringtime determated by Duration",
      "Ringing Strategy": "Ringing Strategy",
      "Ring Group Timeout": "Ring Group Timeout",
      "None": "None",
      "seconds": "seconds",
      "Timeout Destination": "Timeout Destination",
      "Ringgroup Settings": "Ringgroup Settings",
      "Static Caller Phonenumber": "Static Caller Phonenumber",
      "Call Forward": "Call Forward",
      "Greeting Annoucement": "Greeting Annoucement",
      "Ringback": "Ringback",
      "Visible Ringgroups": "Visible Ringgroups",
      "List of available extensions for ringgroups": "List of available extensions for ringgroups",
      "Description Missing": "Description Missing",
      "Ring Group Extensions": "Ring Group Extensions",

      // timeconditions
      "Time Condition": "Time Condition",
      "No Time Conditions Found": "No Time Conditions Found",
      "Start Day": "Start Day",
      "End Day": "End Day",
      "Starttime": "Starttime",
      "Endtime": "Endtime",
      "Destination": "Destination",
      "Invalid value": "Invalid value",
      "Field invalid": "Field invalid",
      "Weekly Conditions": "Weekly Conditions",
      "From": "From",
      "To": "To",
      "Weekly Settings": "Weekly Settings",
      "Add Condition": "Add Condition",
      "Preset": "Preset",
      "From Date": "From Date",
      "To Date": "To Date",
      "Condition Group": "Condition Group",
      "Alternative Destination": "Alternative Destination",
      "Destination when time does not match": "Destination when time does not match",
      "Start": "Start",
      "End": "End",
      "Active": "Active",
      "Condition": "Condition",
      "Conditions": "Conditions",
      "Remove Condition Group": "Remove Condition Group",
      "Actions": "Actions",

      // general
      "Submiting Error": "Submiting Error",
      "Logout": "Logout",
      "Open": "Open",
      "rows": "rows",
      "No Rows Found": "No Rows Found!",
      "Email Address": "Email Address",
      "Login Account": "Login Account",
      "Priveleges": "Priveleges",
      "Avatar": "Avatar",
      "Username": "Username",
      "unknown": "unknown",

      "No Ringgroups Found": "No Ringgroups Found",
      "Active states that may affect call routing": "Active states that may affect call routing",
      "MOBILES ONLY": "MOBILES ONLY",
      "this option is to prevent calls from being routed to": "this option is to prevent calls from being routed to",

      // Unhandled calls
      "New Missed Calls": "New Missed Calls",
      "days and Limited to": "days and Limited to",
      "Table shows list of calls that have not been handled within": "Table shows list of calls that have not been handled within",
      "Mark as Handled": "Mark as Handled",
      "No New Missed calls Found": "No New Missed calls Found",
      // handled calls
      "Table shows list of most recent handled calls with limit to": "Table shows list of most recent handled calls with limit to",
      "From": "From",
      "To": "To",
      "Datetime": "Datetime",
      "Handle Time": "Handle Time",
      "Actions": "Actions",
      "Activated": "Activated",
    }
  },
  'fi-FI': {
    translation: {
      "Missed Calls": "Vastaamattomat Puhelut",
      "Handled Calls": "Hoidetut Puhelut",
      "Stats": "Statistiikka",
      "User": "Käyttäjä",
      "Users": "Käyttäjät",
      "User Account": "Käyttäjätili",
      "User Accounts": "Käyttäjätilit",
      "Voicemails": "Vastaajat",
      "voicemail": "vastaajaan",
      "Ring Group": "Soittoryhmä",
      "Ring Groups": "Soittoryhmät",
      "Ringgroup": "Soittoryhmä",
      "Extension": "Alaliittymä",
      "Extensions": "Alaliittymät",
      "Call Forwarding": "Soitonsiirto",
      "Members": "Jäsenet",
      "Open Page": "Avaa Sivu",
      "More": "Lisää",
      "Return": "Palaa Takaisin",
      "Click for more details": "Napsauta saadaksesi lisätietoja",
      "Back": "Takaisin",
      "Manage Ringgroup Extensions": "Hallitse soittoryhmän Alaliittymiä",
      "Confirm Calls": "Vahvista puhelut",
      "Delay": "Viivästys",
      "Duration of ringing the extension": "Alaliittymän soiton kesto",
      "Ring Duration": "Soiton kesto",
      "Time when extension ends ringing": "Aika, jolloin alaliittymän soitto päättyy",
      "Ringing Ends": "Soiton Päättyminen",
      "Activated": "Aktivoitu",
      "Delay and Duration make up": "Viive ja kesto korvaavat",
      "seconds as ending time": "sekuntia lopetusaikana",
      "Ringgroup Timeout Option cuts off before extension has completed delay with duration ringing": "Soittoryhmän aikakatkaisu -vaihtoehto katkaisee, ennen kuin laajennus on suorittanut viiveen kestoisen soittoäänen aikana",
      "APPLY CHANGES": "OTA MUUTOKSET KÄYTTÖÖN",
      "UNDO CHANGES": "PERU MUUTOKSET",
      "Reminder": "Muistutus",
      "Remember to apply changes to take": "Muista ottaa muutokset käyttöön",
      "affect": "vaikuttaa",
      "Enable Confirm Calls": "Ota Vahvista puhelut käyttöön",
      "User Not Defined": "Käyttäjää ei ole määritetty",
      "unknown": "tuntematon",
      "Simultaneous": "Samanaikainen",
      "Sequence": "Järjestys",
      "Random": "Satunnainen",
      "Appending extensions to ring all by delay and Duration": "Lisätään alaliittymiä soimaan viiveen ja keston mukaan",
      "From 0 to 300 tier. Ringing each at a time with Ring Time": "Tasot 0 - 300. Soittoaika kerrallaan",
      "Randomly rings active extension one at a time, Ringtime determated by Duration": "Satunnaisesti soi aktiivinen alaliittymä yksi kerrallaan, Soittoaika määräytyy keston mukaan",
      "Ringing Strategy": "Soittostrategia",
      "Ring Group Timeout": "Soittoryhmän aikakatkaisu",
      "None": "Ei mitään",
      "seconds": "sekuntia",
      "Timeout Destination": "Aikakatkaisun kohde",
      "Ringgroup Settings": "Soittoryhmän asetukset",
      "Static Caller Phonenumber": "Staattinen soittajan puhelinnumero",
      "Greeting Annoucement": "Tervehdysilmoitus",
      "Ringback": "Soittoääni",
      "Visible Ringgroups": "Näkyvät Soittoryhmät",
      "List of available extensions for ringgroups": "Luettelo käytettävissä olevista laajennuksista soittoryhmille",
      "Description Missing": "Kuvaus puuttuu",
      "Ring Group Extensions": "Soittoryhmän Alaliittymät",


      // timeconditions
      "Time Condition": "Aikaohjaus",
      "No Time Conditions Found": "Aikaohjauksia ei löytynyt",
      "Start Day": "Aloituspäivä",
      "End Day": "Päättymispäivä",
      "Starttime": "Aloitusaika",
      "Endtime": "Loppumisaika",
      "Destination": "Kohde",
      "Invalid value": "Virheellinen arvo",
      "Field invalid": "Kenttä on virheellinen",
      "Weekly Conditions": "Viikoittaiset ehdot",
      "Time Condition Description": "Aikaohjauksen kuvaus",
      "From": "Mistä",
      "To": "Mihin",
      "Weekly Settings": "Viikoittaiset asetukset",
      "Add Condition": "Lisää Aikaohjaus",
      "Preset": "Nimike",
      "From Date": "Päivämäärästä",
      "To Date": "Päivämäärään",
      "Condition Group": "Määritys Ryhmä",
      "Alternative Destination": "Vaihtoehtoinen kohde",
      "Destination when time does not match": "Kohde, jolloin aika ei täsmää",
      "Start": "Aloitus",
      "End": "Lopetus",
      "Start Time": "Aloitus Aika",
      "End Time": "Lopetus Aika",
      "Actions": "Toiminnot",
      "Active": "Aktiivinen",
      "Condition": "Määritys",
      "Conditions": "Määritykset",
      "Remove Condition Group": "Poista Määritys Ryhmä",
      "No Rows found": "Rivejä ei löytynyt",
      "Play": "Soita",

      // announcements
      "Upload Files": "Lataa tiedostoja",
      "Drag'n Drop": "vedä ja pudota",
      "Dialing": "Soittamalla",
      "allows you to record new Announcements": "voit tallentaa uusia äänitteitä",
      "Recording Name": "Tallennuksen nimi",
      "Recording Filename": "Tallennustiedoston nimi",
      "Recording Description": "Tallennuksen kuvaus",
      "CreatedAt": "Luotu",
      "Audio Player": "Audiosoitin",
      "Announcements": "Äänitiedostot",
      "Table shows list of usable Announcements": "Taulukossa on luettelo käytettävissä olevista äänitiedostoista",
      "Text To Speech": "Teksti puheeksi",
      "Delete Selected Announcements": "Poista valitut äänitiedostot",
      "No Audio Content Available": "No Audio Content Available",
      "Active Extensions": "Aktiiviset Alaliittymät",

      // extensions
      "Table shows list of Extensions that exist": "Taulukko näyttää luettelon olemassa olevista laajennuksista",
      "Table shows list of User Accounts that exist and have login priveleges": "Taulukko näyttää luettelon olemassa olevista käyttäjätileistä, joilla on kirjautumisoikeudet",
      "Add Extensions": "Lisää Alaliittymiä",
      "Add User Accounts": "Lisää Käyttäjätilejä",
      "No User Accounts Found": "Käyttäjätilejä ei löytynyt",
      "Outbound CallerID": "Lähtevän soittajan numero",
      "Delete Selected Extensions": "Poista valitut laajennukset",
      "Description": "Kuvaus",
      "Update Extensions": "Päivitä Alaliittymiä",
      "Open Profile": "Avaa Profiili",
      "Admin Priveleges": "Järjestelmänvalvojan oikeudet",
      "Delete Selected Accounts": "Poista valitut tilit",
      "Assign Priveleges": "Määritä oikeudet",

      //RinggroupLinkTable
      "No Ringgroups Found": "Ei soittoryhmiä löytynyt",
      "Select CallForward Destination": "Valitse Soitonsiirtokohde",
      "Enable Call Forwarding": "Aktivoi soitonsiirto käyttöön",
      "Call Forward": "Soitonsiirto",
      "Activation": "Aktivoiti",
      "Most Recent Calls Below": "Viimeisimmät puhelut alla",
      "Time in seconds when ringing is started": "Aika sekunteina, kun soitto alkaa",
      "available values": "saatavilla olevat arvot",
      "Your browser does not support the audio element": "Selaimesi ei tue äänielementtiä",
      "No Audio Content Available": "Äänisisältöä ei ole saatavilla",
      "Search": "Hae",

      // voicemails
      "MailBox": "Äänivastaaja",
      "Greeting Message": "Tervehdysviesti",
      "No Voicemails Found": "Äänivastaajia ei löytynyt",
      "Disable Selected Voicemails": "Poista käytöstä äänivastaajat",
      "Enable Selected Voicemails": "Ota käyttöön äänivastaajat",
      "Enabled Voicemails": "Käytössä olevat äänivastaajat",
      "Disabled Voicemails": "Poistetut äänivastaajat",

      // general
      "Submiting Error": "Lähetysvirhe",
      "Logout": "Kirjaudu ulos",
      "Open": "Avaa",
      "rows": "Riviä",
      "No Rows Found": "Rivejä Ei Löytynyt!",
      "Email Address": "Sähköpostiosoite",
      "Login Account": "Kirjautumistili",
      "Priveleges": "Käyttäoikeudet",
      "Avatar": "Hahmo",
      "Username": "Käyttäjänimi",

      //routes
      "Dashboard": "Päänäkymä",
      "Annoucements": "Äänitiedostot",
      "Time Conditions": "Aikaohjaukset",
      "Voicemails": "Äänivastaajat",
      "Call Log": "Puheluloki",
      "Profiles": "Profiilit",
      "My Profile": "Profiilini",
      "Change Password": "Vaihda Salasana",

      "No Ringgroups Found": "Soittoryhmiä ei löytynyt",
      "Active states that may affect call routing": "Aktiiviset tilat, jotka voivat vaikuttaa puhelun reititykseen",
      "MOBILES ONLY": "VAIN MOBILIT",
      "this option is to prevent calls from being routed to": "Tämä vaihtoehto estää puheluiden siirtymisen puhelinliittymän",

      // Unhandled calls
      "New Missed Calls": "Uudet Vastaamattomat Puhelut",
      "days and Limited to": "päivää ja rajoitettu",
      "Table shows list of calls that have not been handled within": "Taulukko näyttää luettelon puheluista, joita ei ole käsitelty sisällä",
      "Mark as Handled": "Merkitse Hoidetuksi",
      "No New Missed calls Found": "Ei uusia vastaamattomia puheluita",
      // handled calls
      "Table shows list of most recent handled calls with limit to": "Taulukko näyttää luettelon viimeksi käsitellyistä puheluista, joiden rajoitus on",
      "From": "Mistä",
      "To": "Mihin",
      "Datetime": "Aika",
      "Handle Time": "Hoidettu Aika",
      "Actions": "Toiminnot",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //lng: "fi-FI", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    lng: 'en-US',
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;

//i18n.changeLanguage('en-US');
