import * as moment from 'moment';
//import 'moment/locale/fi';

const labelEnum  = {
  'days': 'D',
  'months': 'MMM'
};

const prettyFormats = {
  standard: 'MMM, DD HH:mm:ss',
  withYear: 'YYYY [/] MMM, DD [/] HH:mm',
  simple: 'YYYY MMM DD HH:mm'
};


class Moment {
  chartLabels(range, format) {
    var from = moment().subtract(...range);
    var now = moment();
    var interim = from.clone();
    var timeValues = [];

    while (now > interim || interim.format(labelEnum[range[1]]) === now.format(labelEnum[range[1]])) {
       timeValues.push(interim.format(format));
       interim.add(1, range[1]);
    }

    return timeValues;
  }

  firebaseSeconds(time, format, realtimeDatabase) {
    return moment(new Date(realtimeDatabase ? time : time * 1000)).format(format ? prettyFormats[format] : prettyFormats.withYear);
  }

  minutesToTime(value) {
    if(!value) return value;
    try {
      return moment().startOf('day').add(Number(value), 'minutes').format('HH:mm');
    } catch (err) {
      console.log(err);
    }
  }

  TimetoMinutes(value) {
    if(!value) return value;
    const val = value+':00';
    try {
      //return moment.duration(value).asMinutes(); moment 2.25 pitäis toimia tämä.
      return moment.duration(val).asMinutes();
    } catch (err) {
      console.log(err);
    }
  }

  formatPretty(value) {
    return !value ? '' : moment(value).format(prettyFormats.withYear);
  }

  Humanize(time, format) {
    const t = format ? moment(time, format) : moment(time);
    return (moment().diff(t, 'days') > 3) ? t.format(prettyFormats.standard) : t.fromNow();
  }

  hoursAsLabels(format) {
    return Array.from(Array(24).keys()).map(index => moment( {hour: index} ).format(format));
  }

  timestamp() {
    return moment().format();
  }

  difference(start, end, mesure) {
    const value = start.diff(end, mesure);
    return value;
  }

  Duration(value) {
    if(typeof value !== 'number') return value;

    function getFormat(value) {
      if(value < 60) {
        return "s[s]";
      } else if (value < 3600) {
        return "m[m] s[s]";
      } else {

        return "H[h] m[m] s[s]";
      }
    };

    try {
      return moment().startOf('day').seconds(value).format(getFormat(value));
    } catch (err) {
      console.log(err);
    }
  }


  command(cb) {
    return cb(moment);
  }

  format(time, format) {
    return moment(time).format(format);
  }

  WeekDays() {
    return moment.weekdays();
  }
};

export default new Moment();
