import {
  TIMECONDITION_PENDING,
  TIMECONDITION_SUCCESS,
  TIMECONDITION_FAILURE,
  TIMECONDITIONS_PENDING,
  TIMECONDITIONS_SUCCESS,
} from '../../types';

const TIMECONDITIONS_INITIALSTATE = {
  loading: null,
  data: []
};

export function timeconditions_Reducer(state = TIMECONDITIONS_INITIALSTATE, action) {
  switch (action.type) {
    case TIMECONDITIONS_PENDING:
      return {
        ...state,
        loading: true
      };
    case TIMECONDITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    default:
      return state;
  }
}

// timecondtion view

const TIMECONDITION_INITIALSTATE = {
  loading: false,
  data: {},
  dialplan: {},
  counter: 0,
  error: null
};

export function timecondition_Reducer(state = TIMECONDITION_INITIALSTATE, action) {
  switch (action.type) {
    case TIMECONDITION_PENDING:
      return {
        ...state,
        loading: true
      };
    case TIMECONDITION_SUCCESS:
      return Object.assign({}, state, {
        counter: ++state.counter,
        loading: false,
        ...action.payload
      });
    case TIMECONDITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
