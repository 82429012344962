import {
  FORMLOAD
} from 'store/types';

export function formReducer(state = {}, action) {
  switch (action.type) {
    case FORMLOAD: {
      return { ...Object.assign(
        action.merge ? state : {},
        action.payload
      )}
    }
    default:
      return state;
  }
};

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const setFormValues = (form, data, merge = true) => ({ type: FORMLOAD, merge: merge, payload: { [form]: data } });
