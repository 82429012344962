import {
  MODAL_OPEN,
  MODAL_CLOSE,
} from 'store/types';

const MODAL_INITIALSTATE = {
  open: false,
  component: null,
  customProps: {},
};

export function modal_Reducer(state = MODAL_INITIALSTATE, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        ...action.payload
      };
    case MODAL_CLOSE:
      return {
        ...state,
        open: false,
        ...action.payload
      };
    default:
      return state;
  }
}
