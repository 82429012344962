import Moment from 'devtools/momentjs';
import { v4 as uuidv4 } from 'uuid';

import {
  ANNOUNCEMENTS_PENDING,
  ANNOUNCEMENTS_SUCCESS,
  ANNOUNCEMENTS_FAILURE,
  UPLOAD_ANNOUNCEMENT_PENDING,
  UPLOAD_ANNOUNCEMENT_SUCCESS,
  UPLOAD_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_SAMPLES_PUSH,
  ANNOUNCEMENT_SAMPLES_CHUNK
} from 'store/types';

const ANNOUNCEMENTS_INITIALSTATE = {
  loading: null,
  announcements: [],
  error: null
};

export function announcements_Reducer(state = ANNOUNCEMENTS_INITIALSTATE, action) {
  switch (action.type) {
    case ANNOUNCEMENTS_PENDING:
      return {
        ...state,
        loading: true
      };
    case ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        announcements: action.payload
      };
    case ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

// upload new

const UPLOAD_ANNOUNCEMENT_INITIALSTATE = {
  loading: false,
  announcement: [],
  error: null
};

export function new_announcement_Reducer(state = UPLOAD_ANNOUNCEMENT_INITIALSTATE, action) {
  switch (action.type) {
    case UPLOAD_ANNOUNCEMENT_PENDING:
      return {
        ...state,
        loading: true
      };
    case UPLOAD_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        announcements: action.payload
      };
    case UPLOAD_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

// samples store
const ANNOUNCEMENT_SAMPLES_INITIALSTATE = {
  samples: []
};

export function announcement_samples_reducer(state = ANNOUNCEMENT_SAMPLES_INITIALSTATE, action) {
  switch (action.type) {
    case ANNOUNCEMENT_SAMPLES_PUSH: {
      console.log(action.payload)
      return {
        samples: state.samples.concat(action.payload)
      };
    }

    case ANNOUNCEMENT_SAMPLES_CHUNK:
      return {
        samples: [...action.payload]
      };
    default:
      return state;
  }
}
