import {
  DIDS_PENDING,
  DIDS_SUCCESS
} from 'store/types';

const DIDS_INITIALSTATE = {
  loading: null,
  numbers: []
};

export default function dids_Reducer(state = DIDS_INITIALSTATE, action) {
  switch (action.type) {
    case DIDS_PENDING:
      return {
        ...state,
        loading: true
      };
    case DIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        numbers: action.payload
      };
    default:
      return state;
  }
}
