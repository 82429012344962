import {
  RINGGROUPS_PENDING,
  RINGGROUPS_SUCCESS,
  RINGGROUPS_FAILURE,
  // RINGGROUP_DETAILS_PENDING,
  // RINGGROUP_DETAILS_SUCCESS,
  // RINGGROUP_DETAILS_FAILURE,
  RINGGROUP_PENDING,
  RINGGROUP_SUCCESS,
  FIRESTORE_RINGGROUPS_PENDING,
  FIRESTORE_RINGGROUPS_SUCCESS
} from '../../types';

const RINGGROUPS_INITIALSTATE = {
  loading: null,
  ringgroups: []
};

export function ringgroups_Reducer(state = RINGGROUPS_INITIALSTATE, action) {
  switch (action.type) {
    case RINGGROUPS_PENDING:
      return {
        ...state,
        loading: true
      };
    case RINGGROUPS_SUCCESS:
      return {
        loading: false,
        ringgroups: action.payload
      };
    case RINGGROUPS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

// export function ringgroupDetails_Reducer(state = RINGGROUP_DETAILS_INITIALSTATE, action) {
//   switch (action.type) {
//     case RINGGROUP_DETAILS_PENDING:
//       return {
//         ...state,
//         loading: true
//       };
//     case RINGGROUP_DETAILS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         data: action.payload
//       };
//     default:
//       return state;
//   }
// };

// firestore ringroup details
const RINGGROUP_INITIALSTATE = {
  loading: null,
  ringgroup: null
};

export function ringgroup_Reducer(state = RINGGROUP_INITIALSTATE, action) {
  switch (action.type) {
    case RINGGROUP_PENDING:
      return {
        ...state,
        loading: true
      };
    case RINGGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ringgroup: action.payload
      };
    default:
      return state;
  }
};


// firestore ringroups
const FIRESTORE_RINGGROUPS_INITIALSTATE = {
  loading: null,
  ringgroups: []
};

export function firestore_ringgroups_Reducer(state = FIRESTORE_RINGGROUPS_INITIALSTATE, action) {
  switch (action.type) {
    case FIRESTORE_RINGGROUPS_PENDING:
      return {
        ...state,
        loading: true
      };
    case FIRESTORE_RINGGROUPS_SUCCESS:
      return {
        loading: false,
        ringgroups: action.payload
      };
    default:
      return state;
  }
}
