import {
  EXTENSIONS_CONNECTING,
  EXTENSIONS_DATA,
} from 'store/types';

import { getAvailableExtension } from 'Helpers/Extensions';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  extensions: [],
  extensions_with_user: [],
  extensions_combined: [],
  ringgroup_extensions: [],
  generateAvailableExtension: null
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case EXTENSIONS_CONNECTING:
      return {
        ...state,
        timestamp: new Date(),
        loading: true
      };
    case EXTENSIONS_DATA:
      const data = {
        ...state,
        timestamp: new Date(),
        loading: false,
        ...action.payload
      };

      return {
        ...data,
        generateAvailableExtension: data.extensions.length > 0 ? getAvailableExtension(data.extensions.map(extension => extension.extension)) : null, // generator function
      }
    default:
      return state;
  }
};
