import {
  PASSWORD_RESET_ACTIONS_PENDING,
  PASSWORD_RESET_ACTIONS_SUCCESS,
  PASSWORD_RESET_ACTIONS_FAILURE,
} from '../../types';


const PASSWORD_RESET_ACTIONS_IDLE = {
  state: 'idle',
  loading: false,
  response: null,
  error: null
};

export default function passwordreset_Reducer(state = PASSWORD_RESET_ACTIONS_IDLE, action) {
  switch (action.type) {
    case PASSWORD_RESET_ACTIONS_IDLE:
      return {
        ...state
      };
    case PASSWORD_RESET_ACTIONS_PENDING:
      return {
        ...state,
        state: 'pending',
        loading: true
      };
    case PASSWORD_RESET_ACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        state: 'success',
        response: action.payload
      };
    case PASSWORD_RESET_ACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        state: 'error',
        error: action.payload.error
      };
    default:
      return state;
  }
}
