import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { formReducer } from '../form/reducer';
import { notifier_Reducer } from '../Notifier/reducer';

import sessionReducer from './session';
import firebase_currentUser_reducer from './currentUser';
import firebase_organization_reducer from './organization';
import firebase_numbers_reducer from './numbers';
import firebase_extensions_reducer from './extensions';
import firebase_users_reducer from './users';
import firebase_missedcalls_reducer from './missedcalls';
import firebase_ringgroups_reducer from './ringgroups';
import firebase_voicemails_reducer from './voicemails';
import firebase_announcements_reducer from './announcements';
import firebase_timeconditions_reducer from './timeconditions';
import firebase_profile_reducer from './profile';
import firebase_views_reducer from './views';
import firebase_additionals_reducer from './additionals';
import firebase_filters_reducer from './filters';
import firebase_calls_reducer from './calls';
import firebase_reducer from './firebase';
import test_reducer from './test';

import { profile_Reducer, users_Reducer, updaterstateReducer, firestore_users_Reducer }  from '../profile/reducer';
import passwordreset_Reducer from '../passwordreset/reducer';
import { extensions_Reducer, firestore_extensions_Reducer, firestore_ringgroup_extensions_Reducer, ringgroup_extensions_Reducer } from '../extensions/reducer';
import { ringgroups_Reducer, ringgroup_Reducer, firestore_ringgroups_Reducer } from '../ringgroups/reducer';
import dids_Reducer from '../dids/reducer';
import wizardReducer from '../wizards/reducer';
import { announcements_Reducer } from '../announcements/reducer';
import { announcement_samples_reducer } from '../announcements/reducer';
import { organization_Reducer } from '../organization/reducer';
import { calllog_Reducer } from '../calllog/reducer';
import { timeconditions_Reducer, timecondition_Reducer } from '../timeconditions/reducer';
import { unhandled_missedcalls_Reducer, handled_missedcalls_Reducer } from '../missedcalls/reducer';
import { voicemails_Reducer } from '../voicemails/reducer';
import { errors_Reducer } from '../errors/reducer';
import { modal_Reducer } from '../modals/reducer';

import wizard_states from 'store/wizard_states/reducer';

const rootReducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
  formState: formReducer,
  sessionState: sessionReducer,
  wizardState: wizardReducer,
  notifier_State: notifier_Reducer,
  unhandled_missedcalls_State: unhandled_missedcalls_Reducer,
  handled_missedcalls_State: handled_missedcalls_Reducer,
  firestore_extensions_State: firestore_extensions_Reducer,
  users_State: users_Reducer,
  // firestore_users_State: firestore_users_Reducer,
  ringgroups_State: ringgroups_Reducer,
  ringgroup_State: ringgroup_Reducer,
  ringgroup_extensions_State: ringgroup_extensions_Reducer,
  firestore_ringgroup_extensions_State: firestore_ringgroup_extensions_Reducer,
  firestore_ringgroups_State: firestore_ringgroups_Reducer,
  extensions_State: extensions_Reducer,
  profile_State: profile_Reducer,
  dids_State: dids_Reducer,
  announcements_State: announcements_Reducer,
  announcement_samples: announcement_samples_reducer,
  timecondition_State: timecondition_Reducer,
  timeconditions_State: timeconditions_Reducer,
  organization_State: organization_Reducer,
  calllog_State: calllog_Reducer,
  updaterState: updaterstateReducer,
  passwordreset_State: passwordreset_Reducer,
  voicemails_State: voicemails_Reducer,
  errors_State: errors_Reducer,
  modal_State: modal_Reducer,
  filters: firebase_filters_reducer,
  organization: firebase_organization_reducer,
  profile: firebase_profile_reducer,
  numbers: firebase_numbers_reducer,
  extensions: firebase_extensions_reducer,
  users: firebase_users_reducer,
  missedcalls: firebase_missedcalls_reducer,
  ringgroups: firebase_ringgroups_reducer,
  voicemails: firebase_voicemails_reducer,
  announcements: firebase_announcements_reducer,
  timeconditions: firebase_timeconditions_reducer,
  views: firebase_views_reducer,
  currentUser: firebase_currentUser_reducer,
  additionals: firebase_additionals_reducer,
  calls: firebase_calls_reducer,
  firebase: firebase_reducer,
  test: test_reducer,
  wizard: wizard_states
});

export default rootReducer;
