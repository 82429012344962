import {
  CALLLOG_PENDING,
  CALLLOG_SUCCESS
} from '../../types';

const CALLLOG_INITIALSTATE = {
  loading: null,
  calls: [],
  callsRaw: []
};

export function calllog_Reducer(state = CALLLOG_INITIALSTATE, action) {
  switch (action.type) {
    case CALLLOG_PENDING:
      return {
        ...state,
        loading: true
      };
    case CALLLOG_SUCCESS:
      return {
        loading: false,
        //calls: action.payload.map(item => _.pick(item, CALLLOG_KEYS)),
        calls: action.payload,
        callsRaw: action.payload
      };
    default:
      return state;
  }
}
