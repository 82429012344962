export default class FirebaseListenerClass {

  constructor(ref, success, err, eventType = "value") {
    this.ref = ref;
    this.eventType = eventType;
    this.success = success;
    ref.on(eventType, success, err);
    return this;
  }

  unsubscribe() {
    this.ref.off(this.eventType, this.success);
    //console.log('unsubscribed!')
  }
}
