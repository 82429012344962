const INITIAL_STATE = {
  authUser: null,
};

//action
const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser,
});

// .then((user) => {
//   if(!user) {
//     this.dispatch({ type: CURRENT_USER_DATA, payload: { isAdministator: false, authUser: null }});
//   } else {
//     this.dispatch({ type: CURRENT_USER_DATA, payload: { isAdministator: isAdmin(user.claims.roles), authUser: user.userdata }});
//     currentUser_observable.next(user.userdata);
//     [this.refrences.views, this.refrences.fusionpbx_userAccount] = super.user_events(user.uid);
//   }
// });


function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'AUTH_USER_SET': {
      return applySetAuthUser(state, action);
    }
    default:
      return state;
  }
}

export default sessionReducer;
