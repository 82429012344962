import {
  FIREBASE_CONNECTED,
} from 'store/types';

const INITIALSTATE = {
  loading: true,
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case FIREBASE_CONNECTED:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: false,
        })
      };
    default:
      return state;
  }
};
