import {
  TIMECONDITIONS_CONNECTING,
  TIMECONDITIONS_DATA,
} from 'store/types';

const INITIALSTATE = {
  timestamp: null,
  loading: null,
  timeconditions: []
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case TIMECONDITIONS_CONNECTING:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: true,
        })
      };
    case TIMECONDITIONS_DATA:
      return {
        ...Object.assign(state, {
          timestamp: new Date(),
          loading: false,
          ...action.payload
        })
      };
    default:
      return state;
  }
};
