import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

// import FirebaseEventHandler from './firebase/FirebaseEventHandler';
//
// let handlers;

// export default class Store extends FirebaseEventHandler {
//   constructor(firebase) {
//     function getHandlers() {
//       return handlers || null;
//     };
//
//     const store = createStore(rootReducer,
//       compose(
//         applyMiddleware(
//           thunk.withExtraArgument({ firebase: firebase, handlers: getHandlers })
//         )
//       )
//     );
//
//     handlers = super(firebase, store);
//     //console.log(handler)
//
//     return store;
//   }
// };

export default class Store {
  constructor(firebase) {
    return createStore(rootReducer,
      compose(
        applyMiddleware(
          thunk.withExtraArgument(firebase)
        )
      )
    );
  }
};
