// import React from 'react';
// import { useMediaQuery } from 'react-responsive';
//
// export const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
// export const isBigScreen = useMediaQuery({ minWidth: 1824 });
// export const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
// export const isPortrait = useMediaQuery({ orientation: 'portrait' });
// export const isRetina = useMediaQuery({ minResolution: '2dppx' });

import ScreenSizeDetector from 'screen-size-detector';
export const screen = new ScreenSizeDetector(); // Default options
