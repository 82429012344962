import React from 'react';
import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const BREAKPOINTS = {
  xs: 0,
  sm: 960,
  md: 1280,
  lg: 1420,
  xl: 1680
};

const breakpointsFull = createBreakpoints({
    values: {...BREAKPOINTS},
    keys: Object.keys(BREAKPOINTS),
  });

const myTheme = {
  //other: "stuff"
};

export const theme = createTheme({
   default: myTheme,
   breakpoints: breakpointsFull,
});
