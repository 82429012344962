import {
  FILTERS_DATA,
} from 'store/types';

import { screen } from 'Helpers/Responsive';
import Moment from "devtools/momentjs";

const INITIALSTATE = {
  calllog: {
    dirty: false,
    charts: {
      selectedDatasets: [
        { label: { primary: 'All Calls' }, value: 'All Calls', category: 'Any' }
      ],
      view: 'Daily View',
      gridLayout: screen.is.mobile ? 12 : 6,
    },
    table: {
      to: [],
      from: [],
    },
    state: 'All', // call state
    direction: 'All',  // call direction
    range: {
      start: Moment.command(moment => moment().subtract(30, 'days')),
      end: Moment.command(moment => moment())
    },
    limit: 10000, // firebase row fetch limit
  },
  missedcalls: {
    dirty: false,
    selectedDatasets: [
      { label: { primary: 'All Calls' }, value: 'All Calls', category: 'Any' }
    ],
    view: 'Hourly View',
    gridLayout: 6,
    range: {
      start: Moment.command(moment => moment().subtract(30, 'days')),
      end: Moment.command(moment => moment())
    }
  }
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case FILTERS_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};


// export const applyFilter = (data) => ({ type: FILTERS_DATA, payload: data });

// export const applyFilter = (data) => {
//   return { type: FILTERS_DATA, payload: data };
// };
