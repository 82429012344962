import fetch from 'node-fetch';

export default class HTTPQuery {
  constructor(url, params) {
    return fetch(url, params)
      .then((response) => {
        return this.checkStatus(response);
      })
  }

  checkStatus(response) {
    if (response.ok) {
  		// response.status >= 200 && response.status < 300
  		return response;
  	} else {
  		throw new HTTPResponseError(response);
  	}
  }
}

class HTTPResponseError extends Error {
	constructor(response, ...args) {
		super(`HTTP Error Response: "${response.status}" statusText: "${response.statusText}"`, ...args);
    this.response = response;
	}
}
